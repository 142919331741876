<template>
  <div class="product">    
    <van-nav-bar
        title="供应商列表"
        left-text="返回"    
        left-arrow   
        @click-left="toHome"  
      />    
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check='true'
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-search class="searchInp" v-model="searchValue" placeholder="请输入供应商关键词" shape='round' @clear='clearValue' @input="inputValue" fixed />            
      <van-cell-group class="mt10" v-for="(item, idx) in supplierList" :key="idx">
        <van-cell>
          <template #title>            
            <van-row type="flex" justify="space-between">              
              <van-col span="12"><h4 class="custom-title">{{item.supplier_code}}</h4></van-col>              
              <van-col span="12"><h4 class="custom-title">{{item.supplier_name}}</h4></van-col>
            </van-row>                        
            <van-row type="flex" justify="end">             
              <van-button plain hairline round type="info" size="mini" @click="detection(item)">详 情</van-button>
              <!-- <van-button plain hairline round type="info" size="mini" @click="toEdit(item.id)">编 辑</van-button>
              <van-button plain hairline round type="danger" size="mini" @click='delBtn(item.id,item.sample_name)'>删 除</van-button> -->
            </van-row>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list> 

    <!-- 产品新增 -->
    <van-popup v-model="addShow" class="elasticlayer tac supplier" :closeable='true' position='bottom' round>
      <h3 class="tac">新增供应商</h3>
      <van-cell-group inset height='300px'>         
        <van-field v-model="supplierCode" label="供应商ERP编码" input-align="right" placeholder="请输入供应商erp编码"  />
        <van-field v-model="supplierName" required label="供应商名称" input-align="right" placeholder="请输入供应商名称"  />       
        <van-field v-model="remark" label="备注" input-align="right" placeholder="请输入备注"  />                              
      </van-cell-group>
      <van-button class="" round block size="small" type="primary"  @click="preservAdd" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>
    <!-- 编辑 -->
    <van-popup v-model="toEditshow" class="elasticlayer tac supplier" :closeable='true' position='bottom' round>
      <h3 class="tac">编辑供应商</h3>
      <van-cell-group inset height='300px'>             
        <van-field v-model="supplierCode" label="供应商ERP编码" input-align="right" placeholder="请输入供应商erp编码"  readonly />  
        <van-field v-model="supplierName" required label="供应商名称" input-align="right" placeholder="请输入供应商名称"  />       
        <van-field v-model="remark" label="备注" input-align="right" placeholder="请输入备注"  />                
      </van-cell-group>
      <van-button class="" round block size="small" type="primary"  @click="preservEdit" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>
    <!-- <div class="add-one" title="新增产品" @click="addOne" >
      <van-icon name="plus" color="#fff" size="50" />
    </div>        -->
    <!-- 同步供应商 -->
    <div class="add-one" title="同步供应商" @click="synSupplier" v-track="{triggerType:'click', title:'同步供应商',currentUrl: $route.path,behavior: '同步供应商', duration: new Date().getTime()}">
      <van-icon name="replay" color="#fff" size="50" />
    </div>
    <van-overlay :show="layShow">
      <van-loading size="24px" vertical v-show="allLoading" color="#fff">正在同步，请稍等...</van-loading>
    </van-overlay>
       <!-- 详情 -->
    <van-popup v-model="checkShow" class="elasticlayer supplier" :closeable='true' position='bottom' round>
    <h3 class="tac">{{supplierName}}详情</h3>
    <van-cell-group inset>
      <van-cell title="供应商ERP编码" :value="supplierCode" />
      <van-cell title="供应商名称" :value="supplierName" />
      <van-cell title="备注" :value="remark" />
    </van-cell-group>   
    </van-popup>    
  </div>
</template>
<script>
import Vue from 'vue';
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog, Icon, Search, DropdownMenu, DropdownItem, ActionSheet,Loading,Overlay,NavBar  } from 'vant';
import { parse } from 'qs';
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Search);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(NavBar);
export default {  
  name: 'Supplier',
  components: {},
  
  data() {    
    return {
      count: 0,
      page: 1,
      offset: 10,  
      layShow: false,
      allLoading: false,    
      searchValue: '',      
      loading: false,
      finished: false,      
      addShow: false,
      toEditshow: false,
      editId: '', //编辑id  
      checkShow: false, //供应商详情弹窗         
      supplierList: [], //供应商列表
      supplierCode: '', //供应商编码
      supplierName: '', //供应商名称
      remark: '', //备注     
    };
  },
  created: function () {     
  },
  mounted() {    
  },
  methods: {          
    onLoad () {
      var params = {
        page: this.page++,
        offset: this.offset,
        supplier_name: this.searchValue
      }
      this.$get('supplier/get-supplier-list', 'api', params).then(res => {
        var data = res.data.list
        var length1 = res.data.list.length
        var length2 = res.data.count
        this.count = res.data.count
        this.loading = false;
        this.supplierList = this.supplierList.concat(data);//追加数据           
        if (length1 >= length2) {  //数据全部加载完成
          this.finished = true;         
        }
      }).catch(err => {
        console.log(err)
        this.finished = true;
      })      
    },
    // 搜索
    inputValue(value) { 
      this.searchValue = value    
      this.supplierList = []
      this.finished = false;
      this.loading = true 
      this.page = 1
      this.onLoad()                              
    },        
    // 点击 x
    clearValue() {
      this.supplierList = []
      this.finished = false;
      this.loading = true 
      this.page = 1
      this.onLoad()      
    },
    // 供应商详情
    detection(item) {       
      var that = this 
      this.checkShow = true     
      this.$post('supplier/get-supplier-detail', 'api', { id: item.id}).then(res => {    
        var data = res.data
        that.supplierName = data.supplier_name
        that.supplierCode = data.supplier_erp_code
        that.remark = data.remark
              
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      }) 
    },
    // 同步供应商
    synSupplier() {
      this.layShow = true
      this.allLoading = true
      this.$post('supplier/sync-erp-supplier', 'api', { }).then(res => {         
        this.layShow = false
        this.allLoading = false
        this.$notify({ type: 'success', message: res.message })
        return        
      }, err => {
      
      }) 
    },
    // 编辑
    toEdit(id) {
      var that = this
      that.editId = id
      that.$post('supplier/get-supplier-detail', 'api', { id: id}).then(res => {         
        var data = res.data 
        that.supplierCode = data.supplier_erp_code 
        that.supplierName = data.supplier_name
        that.remark = data.remark
        return        
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      })      
      this.toEditshow = true 
    },
    // 编辑保存
    preservEdit() {   
      var that = this
      this.toEditshow = false      
      var params = {
        id: this.editId,
        supplier_name: this.supplierName,
        remark: this.remark,       
      }      
      this.$post('supplier/save-supplier', 'api', params).then(res => {
        this.$notify({ type: 'success', message: res.message })
        this.finished = false;
        this.loading = true    
        that.page = 1
        that.supplierList = []
        that.onLoad()
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 新增供应商
    addOne() {
      this.addShow = true    
      this.supplierName = ''
      this.supplierCode = ''
      this.remark = '' 
    },
    // 新增保存
    preservAdd() {      
      var that = this
      this.addShow = false      
      var params = {
        supplier_name: this.supplierName,
        remark: this.remark,
        supplier_erp_code: this.supplierCode,   
        sample_type: 2
      } 
      this.$post('supplier/add-supplier', 'api', params).then(res => {
        this.$notify({ type: 'success', message: res.message })
        that.page = 1
        that.supplierList = []
        this.finished = false;
        this.loading = true 
        that.onLoad()
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 删除
    delBtn(id,name) {      
      var that = this    
      Dialog.confirm({
        title: name,
        message:'确定要删除此供应商',        
      }).then(() => {
        that.$post('supplier/delete-supplier', 'api', { id: id}).then(res => {               
          this.$notify({ type: 'success', message: res.message })
          that.page = 1
          that.supplierList = []
          this.finished = false;
          this.loading = true 
          that.onLoad()
        }, err => {
          console.log(err)
          this.$notify({ type: 'warning', message: err.message })
        })
      }).catch(() => {
        // on cancel
      });
    },    
    // 返回
    toHome() {
      this.$router.push({
        path: '/home'     
      })
    } 
  }          
};
</script>
<style scoped>
.supplier >>> .van-field__label {
  width: 7.2em;
}
.van-loading--vertical {
  top: 50%;
  margin-top: -25px;
}
 .status{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 42px;
  height: 42px;
  z-index: 0;
  opacity: 40%;
  transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg);
 }
 .border-tb{
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
 }
 .elasticlayer {
   height: 60%;
 }
 .tac {
   text-align: center;
 }
 .add-one {
   width: 50px;
   height: 50px;
   background: #1989fa;
   position: fixed;
   bottom: 100px;
   z-index: 99999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
 }
 .red {
   color: red;
 }
 .van-cell-group--inset {
    height: 300px;
    overflow: auto;
}
.icon-del {
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
}
.icon-add {
  margin-left: 20px;
}
.searchInp {
  /* position: fixed;
  top: 0; */
  width: -webkit-fill-available;
  z-index: 99;
}
.import-excel {
   width: 50px;
   height: 50px;
   background: wheat;
   position: fixed;
   bottom: 150px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
}
</style>